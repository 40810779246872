<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="breadcrumb-main">
                                <h4 class="text-capitalize breadcrumb-title">{{ t('welcome',{},{locale:getLocal}) }}</h4>
           
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <p class="color-dark fw-500 fs-20 mb-0">{{ t('welcomeWithCompany',{company:'Renny Lottery'},{locale:getLocal}) }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
// @ is an alias to /src

// import * as serviceAPI  from '../services/API.service';
// import { lightFormat } from 'date-fns'
import { useI18n } from 'vue-i18n'
export default {
  name: 'Welcome',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  data: function () {
    return {

      }
    },
  mounted() {
      console.log("welcome page")
      console.log(this.$store.getters['auth/getloggedIn'])
  },
  methods: {
  },
  computed:{
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    }
  }
//   computed: {
  	
//     showtime(){
//         return lightFormat(new Date(2014, 1, 11), 'yyyy-MM-dd')
//     }
//   }
}

</script>